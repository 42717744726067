.previewhtml-wrapper {
  overflow-y: scroll;
  max-height: 350px;
}
.previewhtml-wrapper p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.previewhtml-wrapper ul {
  list-style: disc;
  list-style-position: inside;
}
.previewhtml-wrapper ul li {
  margin-bottom: 0.8rem;
  padding-left: 1.1rem;
  position: relative;
}